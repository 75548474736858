.user-list-scroll {
  height: 300px;
  overflow: auto;
  border: 1px solid $accent;
  margin-top: 10px !important;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 500px;
  max-height: 575px;
  min-width: 325px;
  max-width: 600px;
  padding: 2rem;
  background-color: $accent;
  border-radius: 0.5rem;
  display: grid;
  place-self: center;
  & > form {
    display: grid;
    place-content: center;
    place-items: center;
    gap: 2rem;
    & > p {
      text-align: center;
      font-weight: 600;
      margin: 0;
    }
    & .MuiFormGroup-root {
      display: inherit;
      grid-template-columns: repeat(3, 1fr);
      padding: 0 2rem;
      width: 100%;
      height: 6rem;
      border: thin solid rgba(0, 0, 0, 0.15);
      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      & label {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        & span:nth-child(2) {
          font-family: 'poppins-semi-bold';
        }
      }
    }
    & > div:nth-of-type(2) {
      width: 80%;
      & .MuiInput-input {
        cursor: pointer;
      }
    }
    & > button {
      width: 50%;
      border-radius: 2rem;
      font-weight: 600;
    }
  }
}
