.forgot__title {
  color: black;
  text-align: center;
  font-family: 'poppins-semi-bold';
  font-weight: 500;
  font-size: 32px;
}

.forgot__card {
  margin-top: 20px;
  padding: 40px;
  max-width: 500px;
  color: $info !important;
  width: 480px;
}
