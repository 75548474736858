.home__box {
  background: $primary-gradient;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
  min-height: 146px;
  text-decoration: none;
  &:hover {
    transform: scale(1.01);
    filter: brightness(105%);
  }
  div :first-child {
    font-size: 18px;
    font-weight: 600;
  }

  div :nth-child(2) {
    font-size: 14px;
  }
  img {
    width: 60px;
  }
}

.home__btn-faq {
  padding: 18px 25px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  font-weight: 400 !important;
  justify-content: space-between !important;
  &:hover {
    transform: scale(1.01);
  }
}

.home__box-tertiary {
  background: $tertiary-gradient;
  color: white;
  padding: 20px;
  border-radius: 15px;
}

.mobile-use {
  border: 2px solid $tertiary;
  border-radius: 15px;
  .head {
    padding: 18px 20px;
    background: $tertiary-gradient;
    color: white;
    line-height: 24px;
    border-radius: 12px 12px 0 0;
  }
  .body {
    padding: 25px;
    font-size: 14px;
  }
}

.btn-arrow-down {
  position: absolute;
  left: 6px;
}

.faqs__table {
  border-collapse: collapse;
  font-size: 12px;
  th {
    background: $accent;
  }
  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
  }

  th,
  td {
    padding: 9px;
  }
}
