// Colors
:root {
  --primary: #ff4d4b;
  --secondary: #383838;
  --tertiary: #7a4ae2;
  --error: #ff3633;
  --warning: #ffb74d;
  --info: #757575;
  --success: #32c8bf;
  --accent: #ececec;
}

$primary: var(--primary);
$secondary: var(--secondary);
$tertiary: var(--tertiary);
$error: var(--error);
$warning: var(--warning);
$info: var(--info);
$success: var(--success);
$accent: var(--accent);
$primary-gradient: linear-gradient(64.7deg, #f87474 0%, $primary 100%);
$tertiary-gradient: linear-gradient(64.7deg, #a87fff 0%, $tertiary 100%);

.border-tertiary {
  border: 1px solid $tertiary !important;
}

.bg-tertiary {
  background: $tertiary !important;
}

.bg-error {
  background: $error;
}

.bg-accent {
  background: $accent;
}

.bg-success {
  background: $success;
}

.border-success {
  border: 5px solid $success !important;
}

.border-warning {
  border: 5px solid $warning !important;
}

.text-white {
  color: white !important;
}

.text-tertiary {
  color: $tertiary !important;
}

.text-info {
  color: $info !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-success {
  color: $success !important;
}

.text-error {
  color: $error !important;
}

.text-warning {
  color: $warning !important;
}
