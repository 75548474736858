.login__container-left {
  background-color: $secondary;
  height: 100vh;
  color: white;
  padding: 50px 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    div:first-child {
      font-size: 51px;
      font-family: 'poppins-semi-bold';
      line-height: 60px;
      margin-bottom: 20px;
      max-width: 650px;
      @include rwd($breakpoint-md) {
        line-height: initial;
        font-size: 24px;
      }
    }
    div:nth-child(2) {
      max-width: 650px;
      font-size: 22px;
      font-family: 'poppins-medium';
      margin-bottom: 30px;
      @include rwd($breakpoint-md) {
        font-size: 18px;
      }
    }
  }
  @include rwd($breakpoint-md) {
    height: initial;
    padding: 25px;
  }
}

.login__card {
  margin-top: 20px;
  padding: 40px;
  max-width: 400px;
  color: $info !important;
  width: 400px;
  @include rwd($breakpoint-md) {
    padding: 25px;
    width: 330px;
  }
}

.login__link {
  color: $primary;
  font-size: 12px;
  text-decoration: underline;
  &:hover {
    filter: brightness(90%);
  }
}

.img-mailbox-locker {
  display: none;
  max-width: 450px;
  max-height: 450px;
}
