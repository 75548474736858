@for $i from 0 through 8 {
  .m-#{$i} {
    margin: $i * 10 + px !important;
  }

  .mt-#{$i} {
    margin-top: $i * 10 + px !important;
  }

  .mr-#{$i} {
    margin-right: $i * 10 + px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 10 + px !important;
  }

  .ml-#{$i} {
    margin-left: $i * 10 + px;
  }

  .my-#{$i} {
    margin-top: $i * 10 + px;
    margin-bottom: $i * 10 + px;
  }

  .mx-#{$i} {
    margin-left: $i * 10 + px;
    margin-right: $i * 10 + px;
  }

  .p-#{$i} {
    padding: $i * 10 + px !important;
  }

  .pt-#{$i} {
    padding-top: $i * 10 + px;
  }

  .pr-#{$i} {
    padding-right: $i * 10 + px;
  }

  .pb-#{$i} {
    padding-bottom: $i * 10 + px;
  }

  .pl-#{$i} {
    padding-left: $i * 10 + px;
  }

  .py-#{$i} {
    padding-top: $i * 10 + px;
    padding-bottom: $i * 10 + px;
  }

  .px-#{$i} {
    padding-left: $i * 10 + px;
    padding-right: $i * 10 + px;
  }

  .space-y-#{$i} > * + * {
    margin-top: $i * 10 + px;
  }

  .space-x-#{$i} > * + * {
    margin-left: $i * 10 + px !important;
  }

  .r-#{$i} {
    border-radius: $i * 5 + px !important;
  }
}
