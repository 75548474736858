* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'dm-sans-regular';
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

h1 {
  font-family: 'poppins-medium';
  margin: 0;
  font-size: 32px;
}

h2 {
  font-family: 'poppins-medium';
  margin: 0;
  font-size: 22px;
}

h3 {
  font-family: 'poppins-medium';
  margin: 0;
  font-size: 18px;
}

.max-width-screen {
  width: 100%;
  max-width: 1100px;
}

.overlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-x {
  overflow-x: scroll;
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

input,
textarea {
  color: $info !important;
  font-size: 14px !important;
}

.label-input {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
}

.label-radio {
  display: block;
  font-size: 13px;
}

.events-none {
  pointer-events: none !important;
  user-select: none;
}

.decoration-none {
  text-decoration: none;
}

img {
  pointer-events: none !important;
  user-select: none;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.flex-grow {
  flex-grow: 1;
  overflow: auto;
}

.flex-1 {
  flex: 1;
}

.full-view {
  height: 100vh;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.max-400 {
  max-width: 400px;
}

.max-300 {
  max-width: 300px;
}

.row {
  display: flex;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

.btn-round {
  border-radius: 30px !important;
}

.btn-rounded {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  min-width: auto !important;
}

.btn-xlarge {
  padding: 10px 25px !important;
  font-size: 16px !important;
}

.scale {
  &:hover {
    transform: scale(1.01);
  }
}

.relative {
  position: relative;
}

.sidenav-width {
  width: 100vw;
  max-width: 400px;
}

.underline {
  text-decoration: underline;
}

.btn-group {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @for $i from 1 through 4 {
    button:nth-child(#{$i}) {
      margin-left: -15px;
      z-index: $i;
      background: white;
    }
  }
  button:hover {
    filter: brightness(95%);
  }
}

.avatar-size {
  width: 55px !important;
  height: 55px !important;
}

.rounded {
  border-radius: 50%;
}

.translate-absolute {
  position: absolute;
  top: 5px;
  right: 5px;
}
