p.Mui-error {
  position: absolute !important;
  bottom: -18px;
  font-size: 10px;
}

.MuiButton-root {
  text-transform: initial !important;
  font-family: 'poppins-regular' !important;
}

.paginator .Mui-selected {
  background: $success !important;
  color: white;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $success !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: $success !important;
}

.MuiFormControlLabel-label {
  font-size: 13px !important;
}

.directories__toggle .MuiToggleButton-root {
  text-transform: initial !important;
  border: 1px solid $success !important;
  color: $success !important;
  padding: 5px 0px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  width: 130px;
}

.directories__toggle .Mui-selected {
  background: $success !important;
  color: white !important;
}
